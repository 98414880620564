<template>
  <div>
    <vx-card>
      <div v-for="(form, key) in formData" :key="key">
        <vs-input
          v-if="form.type ==='text'"
          :name="form.fieldName"
          data-vv-as="fullName"
          :label="form.fieldName"
          class="w-full"
          v-model="formData[key].value"
        />
        <input v-else type="file" :name="form.fieldName" @change="updateCurrImg($event, key)">

      </div>
      <a @click="submitHandler">Save</a>

    </vx-card>
  </div>
</template>
<script>
import Vue from "vue";
import VueScrollTo from "vue-scrollto";
import axios from "../../../axios";
import _ from "lodash";

Vue.use(VueScrollTo);

export default {
  data() {
    return {
      formData: [
        {
          label: 'Name',
          fieldName: 'name',
          value: '',
          type: 'text'
        },
        {
          label: 'Address',
          fieldName: 'address',
          value: '',
          type: 'text'
        },
        {
          label: 'Image',
          fieldName: 'image',
          value: '',
          type: 'file'
        },
        {
          label: 'Document',
          fieldName: 'document',
          value: '',
          type: 'file'
        },
        {
          label: 'Email',
          fieldName: 'email',
          value: '',
          type: 'text'
        },
      ],
    };
  },
  methods: {
    submitHandler() {
      let data = new FormData();
      data.append(`form`, '5f57a3f7b074831ca0e3eda2');
      data.append(`room`, '5f6b829fd6421935fa3ad3b6');
      data.append(`user`, '5f735b0a8e28ae1defa810c9');
      _.forEach(this.formData, function (value, key) {
        data.append(`fieldName[${key}]`, value.fieldName);
        data.append(`value[${key}]`, value.value);
        data.append(`type[${key}]`, value.type);
      });
      // data.append(`data`, this.formData);

      axios.post('/userFormData', data);
    },
    updateCurrImg(input, index) {
      if (input.target.files && input.target.files[0]) {
        this.formData[index].value = input.target.files[0];
      }

    }
  },
  mounted() {
    console.log('i ma here at form ')

  },
};
</script>
